export default {
  parts: ["container", "requiredIndicator", "helperText"],
  baseStyle: {
    container: {
      label: {
        apply: "textStyles.label",
        _invalid: {
          apply: "layerStyles.danger",
        },
      },
    },
    helperText: {
      apply: "textStyles.body",
      color: "muted",
      textAlign: "center",
    },
  },
};
