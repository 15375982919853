import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

// ref - https://chakra-ui.com/docs/components/tabs/theming
const baseStyle = definePartsStyle({
  tablist: {
    bg: "inset",
    rounded: "md",
  },
  tab: {
    m: 1,
    bg: "inset",
    _selected: {
      bg: "blue",
      color: "white",
      rounded: "md",
      boxShadow: "sm",
    },
  },
});

// export the component theme
export default defineMultiStyleConfig({ baseStyle });
