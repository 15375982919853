import { defineStyle } from "@chakra-ui/styled-system";

export default {
  global: defineStyle({
    "*::placeholder": {
      opacity: 1,
      color: "muted",
    },
    "*, *::before, &::after": {
      borderColor: "inactive",
    },
    "html,body": {
      height: "100%",
      bg: "white",
      padding: "0px",
      margin: "0px",
    },
    a: {
      color: "blue",
    },
  }),
};
