const defaultVariant = () => ({
  button: {
    apply: "textStyles.body",
    bg: "inset",
    borderRadius: "4px",
    border: "2px solid",
    borderColor: "transparent",
    textAlign: "left",
    px: "12px",
    py: "8px",
    transition: "all 0.1s",
    _hover: {
      borderColor: "blue",
      bg: "bg.blue",
      color: "gray",
    },
    _focus: {
      borderColor: "blue",
      bg: "inset",
      color: "gray",
    },
    _active: {
      borderColor: "blue",
      borderRadius: "4px 4px 0 0",
      bg: "inset",
      color: "gray",
      zIndex: 999,
    },
  },
  list: {
    bg: "inset",
    boxShadow: "lg",
    border: "2px solid",
    borderColor: "blue",
    borderRadius: "0 0 4px 4px",
    zIndex: 999,
  },
  item: {
    apply: "textStyles.body",
    minHeight: "40px",
    pl: "16px",
    bg: "inset",
    _hover: {
      bg: "white",
    },
    _focus: {
      bg: "white",
    },
  },
  groupTitle: {
    apply: "textStyles.caption",
    height: "30px",
  },
});

const outlineVariant = () => {
  const base = defaultVariant();
  base.button = {
    ...base.button,
    bg: "transparent",
    border: "2px solid",
    borderColor: "blue",
  };
  return {
    ...base,
  };
};

const detachedVariant = () => ({
  list: {
    bg: "inset",
    boxShadow: "lg",
    border: "2px solid",
    borderColor: "blue",
    borderRadius: "4px",
    zIndex: 999,
  },
  item: {
    apply: "textStyles.body",
    minHeight: "40px",
    pl: "16px",
    bg: "inset",
    _hover: {
      bg: "white",
    },
    _focus: {
      bg: "white",
    },
  },
  groupTitle: {
    apply: "textStyles.caption",
    height: "30px",
  },
});

const variants = {
  default: defaultVariant,
  outline: outlineVariant,
  detached: detachedVariant,
};

export default {
  variants,
  defaultProps: {
    variant: "default",
  },
};
