const baseStyle = {
  container: {
    rounded: "4px",
    // overflow: "hidden",
  },
};

const variants = {
  default: {
    header: {
      bg: "white",
    },
    body: {
      bg: "white",
      overflow: "auto",
    },
    footer: {
      bg: "white",
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  compact: {
    header: {
      bg: "white",
      p: 4,
      pb: 0,
    },
    body: {
      bg: "white",
      overflow: "auto",
      p: 4,
      pt: 0,
    },
    footer: {
      bg: "white",
      display: "flex",
      justifyContent: "flex-end",
    },
  },
};

export default {
  baseStyle,
  variants,
  defaultProps: {
    variant: "default",
  },
};
