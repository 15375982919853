const semanticTokens = {
  colors: {
    black: "#282828",
    gray: "#464646",
    white: "#ffffff",
    red: "#ff647c",
    blue: "#0a71af",
    lightBlue: "#0d89d4",
    subtle: "#f8f8f8",
    surface: "#f1f4f8",
    muted: "#888888",
    inset: "#edf4f8",
    inactive: "#dcdcdc",

    // fixed colors
    // purple: {
    //   default: "#8046c1",
    //   _dark: "#EEB5EB",
    // },
    // turquoise: {
    //   default: "#3CACAE",
    //   _dark: "#C8F4F9",
    // },
    // midnightblue: {
    //   default: "#41729F",
    //   _dark: "#5885AF",
    // },
    // tan: {
    //   default: "#D0B49F",
    //   _dark: "#E4D4C8",
    // },
    // brown: {
    //   default: "#523A28",
    //   _dark: "#A47551",
    // },
    // mauve: {
    //   default: "#B99095",
    //   _dark: "#FCB5AC",
    // },
    // mint: {
    //   default: "#447675",
    //   _dark: "#B5E5CF",
    // },
    // coffee: {
    //   default: "#67595E",
    //   _dark: "#A49393",
    // },
    // rose: {
    //   default: "#E8B4B8",
    //   _dark: "#EED6D3",
    // },

    // "Sunshine Yellow": "#FFD700",
    // "Ocean Blue": "#0077BE",
    // "Forest Green": "#228B22",
    // "Cherry Red": "#FF0000",
    // "Lavender Lilac": "#C8A2C8",
    // "Tangerine Tango": "#FFA500",
    // "Emerald Envy": "#50C878",
    // "Royal Purple": "#7851A9",
    // "Coral Crush": "#FF6F61",
    // "Silver Mist": "#C0C0C0",
    // "Midnight Indigo": "#191970",
    // "Ruby Red": "#E0115F",
    // "Lemon Zest": "#FFF44F",
    // "Skyline Blue": "#5D8AA8",
    // "Minty Fresh": "#00FFC1",
    // "Plum Passion": "#8E4585",
    // Goldenrod: "#DAA520",
    // "Teal Tease": "#008080",
    // "Blush Pink": "#FF6B81",
    // "Slate Gray": "#708090",
    // "Cinnamon Spice": "#D2691E",
    // "Sapphire Serenity": "#006994",
    // "Rose Petal": "#FF66CC",
    // "Azure Breeze": "#007FFF",
    // Buttercream: "#F5E1AA",

    purple: "#8046c1",
    olivegreen: "#556B2F",
    brown: "#523A28",
    slategray: "#2F4F4F",
    violet: "#483D8B",
    goldenrod: "#B8860B",
    rose: "#E8B4B8",
    magenta: "#8B008B",
    midnightblue: "#41729F",
    coffee: "#67595E",
    seagreen: "#8FBC8F",
    mint: "#447675",
    mauve: "#B99095",
    turquoise: "#3CACAE",
    salmon: "#E9967A",
    tan: "#D0B49F",

    task: "#F5EDF8",
    sensor: "#FFF0E1",
    movementsession: "#DCF1DF",
    safetyTraining: "#EDF4F8",

    /*
     * Score colors
     */
    "score.very-low": { default: "green.500", _dark: "green.400" },
    "score.low": { default: "green.300", _dark: "green.200" },
    "score.moderate": { default: "orange.400", _dark: "orange.200" },
    "score.high": { default: "red.200", _dark: "red.200" },
    "score.very-high": { default: "red.400", _dark: "red.300" },

    /*
     * Sensor dataset colors
     */
    "sensor.arm": { default: "orange.400", _dark: "orange.200" },
    "sensor.back": { default: "blue.500", _dark: "blue.300" },
    "sensor.legs": {
      default: "purple.500",
      _dark: "purple.300",
    },
    "dataset.green": {
      default: "green.500",
      _dark: "green.300",
    },
  },
};

export type SemanticTokens = typeof semanticTokens;

export default semanticTokens;
