export default {
  baseStyle: {
    dialog: {
      borderRadius: { base: "none", md: "8px" },
      boxShadow: "dark-lg",
    },
    header: {
      apply: "textStyles.title",
      fontWeight: "normal",
      borderBottom: "2px solid",
      borderBottomColor: "blue",
      borderRadius: { base: "none", md: "8px 8px 0 0" },
    },
  },
};
