export default {
  baseStyle: {
    text: {
      color: "red",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
      marginLeft: "18px",
    },
  },
};
