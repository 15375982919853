// const baseStyle = {
// 	border: "2px solid",
// 	borderRadius: "8px",
// 	bg: "inset",
// 	borderColor: "transparent",
// 	// _hover: { borderColor: "blue" },
// 	// _focus: {
// 	// 	borderColor: "blue",
// 	// },
// 	// _disabled: {
// 	// 	borderColor: "transparent",
// 	// 	bg: "inactive",
// 	// 	color: "gray",
// 	// },
// 	// _invalid: {
// 	// 	borderColor: "red",
// 	// 	bg: "white",
// 	// 	color: "red",
// 	// },
// };
// export default {
// 	baseStyle,
// };
import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = defineStyle({
  border: "2px solid",
  borderRadius: "8px",
  borderColor: "transparent",
});

const outline = defineStyle({
  bg: "transparent",
});

const filled = defineStyle({
  bg: "inset",
});

export default defineStyleConfig({
  baseStyle,
  variants: { outline, filled },
  defaultProps: {
    variant: "filled",
  },
});
