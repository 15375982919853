import type { I18nLocale } from "~/libs/i18n";
import enGB from "./en-GB.ts";
import enAU from "./en-AU.ts";
import es from "./es.ts";
export const locales: I18nLocale = {
  "en-US": {
    "Language-en-US": "English",
    SupportEmail: "injuryprevention@bardavon.com",
    supervisor: "Safety Pro",
    worker: "Worker",
    rtw_worker: "RTW worker",

    good_task_low_arm_load:
      "Great work - this task has low load on the worker's arm and shoulders",
    train_smooth_arm_movement:
      "Manage the worker's load by encouraging smooth movement through the arm and shoulders",
    ensure_balanced_body_and_smooth_movements:
      "Ensure the worker's body is balanced and arm movements are smooth to reduce load",
    good_for_arm_in_moderation:
      "In moderation, this task will be a good way to build resilience in the worker's arm and shoulder muscles",
    ensure_adequate_breaks:
      "Ensure these tasks are completed with adequate breaks to minimise risk of overload and fatigue",
    arm_possible_task_modification:
      "Where possible, modify the task or train the worker to avoid rapid arm movements in different directions to reduce risk of neural fatigue",
    arm_avoid_position_for_extended_periods:
      "Workers should perform tasks in these positions for no longer than 2-3 minutes to reduce arm fatigue",
    train_worker_on_arm_movements:
      "Reduce load on the worker's arms and shoulders by bringing the elbows closer to the body",
    arm_modify_task:
      "With high range and control, the task must be modified by moving hands and elbows closer to the body and encouraging smooth, controlled arm movements",

    good_task_low_back_load:
      "Great work - this task has low load on the worker's back",
    train_smooth_back_movement:
      "Manage the worker's load by encouraging smooth movement through the body",
    // ensure_balanced_body_and_smooth_movements:
    //   "Ensure the body is balanced and movements are smooth to reduce load",
    good_for_back_in_moderation:
      "In moderation, this task will be a good way to build resilience in the worker's trunk and back muscles",
    // ensure_adequate_breaks:
    //   "Ensure these tasks are completed with adequate breaks to minimise risk of overload and fatigue",
    back_possible_task_modification:
      "Where possible, modify the task or train the worker to avoid rapid body movements in different directions to reduce risk of neural fatigue",
    back_avoid_position_for_extended_periods:
      "Workers should perform tasks in these positions for no longer than 2-3 minutes to reduce back and trunk fatigue",
    train_worker_on_back_movements:
      "Reduce load on the worker's back by training workers to move with a more upright position and use their legs to move their body",
    back_modify_task:
      "With high range and control, this task must be modified by moving objects closer to waist height and ensuring workers move with smooth, controlled body movements",
    unclassified: "Unclassified",

    //Sorting
    "Sorted by ": "Sorted by ",
    " then ": " then ",
    desc: "desc",
    asc: "asc",
    "assignedToUser/firstName": "Worker",
    "location/name": "Location",
    "Task/name": "Task",
    "Occupation/name": "Occupation",
    startTime: "Time",
    reportScore: "Score",
    armLoadScore: "ArmLoadScore",
    backLoadScore: "BackLoadScore",
    legLoadScore: "LegLoadScore",

    "Add x more for a more reliable result": {
      0: "",
      1: "Add 1 more session for a more reliable result",
      other: "Add {count} more sessions for a more reliable result",
    },

    "Remove x members from account": {
      0: "Select members to remove",
      1: "Remove 1 member",
      other: "Remove {count} members",
    },

    "These users will be removed": {
      0: "",
      1: "This user will be removed",
      other: "These {count} users will be removed",
    },

    "These modules will be assigned": {
      0: "Select modules",
      1: "Assign this module",
      other: "Assign these {count} modules",
    },

    "to these users": {
      0: "Select users",
      1: "To this user",
      other: "To these {count} users",
    },

    // error messages
    company_name_already_used:
      "The name is already taken, please select another one",
    company_prefix_already_used:
      "The prefix is already in use. Please select a different prefix",
  },
  "en-GB": enGB,
  "en-AU": enAU,
  es,
};
