export default {
  normal: {
    brand: {
      50: "#fefce8",
      100: "#fef9c3",
      200: "#fef08a",
      300: "#fde047",
      400: "#facc15",
      500: "#ffcc00",
      600: "#eab308",
      700: "#ca8a04",
      800: "#a16207",
      900: "#854d0e",
    },
    gray: {
      900: "#2b2b2b",
      800: "#424242",
      700: "#616161",
      600: "#757575",
      500: "#9e9e9e",
      400: "#bdbdbd",
      300: "#e0e0e0",
      200: "#e6e6e6",
      100: "#f5f5f5",
      50: "#fafafa",
    },

    blueGray: {
      900: "#263238",
      800: "#37474f",
      700: "#455a64",
      600: "#546e7a",
      500: "#607d8b",
      400: "#78909c",
      300: "#90a4ae",
      200: "#b0bec5",
      100: "#cfd8dc",
      50: "#eceff1",
    },

    yellow: {
      900: "#f57f17",
      800: "#f9a825",
      700: "#fbc02d",
      600: "#fdd835",
      500: "#ffeb3b",
      400: "#ffee58",
      300: "#fff176",
      200: "#fff59d",
      100: "#fff9c4",
      50: "#fffde7",
    },

    blue: {
      900: "#0d47a1",
      800: "#1565c0",
      700: "#1976d2",
      600: "#1e88e5",
      500: "#2196f3",
      400: "#42a5f5",
      300: "#64b5f6",
      200: "#90caf9",
      100: "#bbdefb",
      50: "#e3f2fd",
    },

    cyan: {
      900: "#006064",
      800: "#00838f",
      700: "#0097a7",
      600: "#00acc1",
      500: "#00bcd4",
      400: "#26c6da",
      300: "#4dd0e1",
      200: "#80deea",
      100: "#b2ebf2",
      50: "#e0f7fa",
    },

    purple: {
      900: "#4a148c",
      800: "#6a1b9a",
      700: "#7b1fa2",
      600: "#8e24aa",
      500: "#9c27b0",
      400: "#ab47bc",
      300: "#ba68c8",
      200: "#ce93d8",
      100: "#e1bee7",
      50: "#f3e5f5",
    },

    red: {
      900: "#b71c1c",
      800: "#c62828",
      700: "#d32f2f",
      600: "#e53935",
      500: "#f44336",
      400: "#ef5350",
      300: "#e57373",
      200: "#ef9a9a",
      100: "#ffcdd2",
      50: "#ffebee",
    },

    orange: {
      900: "#e65100",
      800: "#ef6c00",
      700: "#f57c00",
      600: "#fb8c00",
      500: "#ff9800",
      400: "#ffa726",
      300: "#ffb74d",
      200: "#ffcc80",
      100: "#ffe0b2",
      50: "#fff3e0",
    },

    green: {
      900: "#1b5e20",
      800: "#2e7d32",
      700: "#388e3c",
      600: "#43a047",
      500: "#4caf50",
      400: "#66bb6a",
      300: "#81c784",
      200: "#a5d6a7",
      100: "#c8e6c9",
      50: "#e8f5e9",
    },
  },
  colorblind_safe: {
    brand: {
      50: "#fefce8",
      100: "#fef9c3",
      200: "#fef08a",
      300: "#fde047",
      400: "#facc15",
      500: "#ffcc00",
      600: "#eab308",
      700: "#ca8a04",
      800: "#a16207",
      900: "#854d0e",
    },
    gray: {
      900: "#000000",
      800: "#1a1a1a",
      700: "#2e2e2e",
      600: "#434343",
      500: "#595959",
      400: "#707070",
      300: "#888888",
      200: "#a1a1a1",
      100: "#bababa",
      50: "#fcfcfc",
    },

    blueGray: {
      900: "#000000",
      800: "#1a1a1a",
      700: "#2e2e2e",
      600: "#434343",
      500: "#595959",
      400: "#707070",
      300: "#888888",
      200: "#a1a1a1",
      100: "#bababa",
      50: "#d4d4d4",
    },

    yellow: {
      900: "#500000",
      800: "#6e0800",
      700: "#852200",
      600: "#9d3700",
      500: "#b54b00",
      400: "#ce5f00",
      300: "#e7740c",
      200: "#fa8c27",
      100: "#ffac49",
      50: "#ffcc68",
    },

    blue: {
      900: "#000b58",
      800: "#002172",
      700: "#03378d",
      600: "#184ca7",
      500: "#3d60bf",
      400: "#5975d7",
      300: "#738bf0",
      200: "#8ea4fe",
      100: "#aabeff",
      50: "#c6d8ff",
    },

    cyan: {
      900: "#002024",
      800: "#00353a",
      700: "#004c50",
      600: "#066468",
      500: "#2c7b7f",
      400: "#479396",
      300: "#61abaf",
      200: "#7ac4c8",
      100: "#93dee2",
      50: "#adf9fc",
    },

    purple: {
      900: "#0f005b",
      800: "#340478",
      700: "#501991",
      600: "#6730a9",
      500: "#8046c1",
      400: "#985cd9",
      300: "#b072f2",
      200: "#cc8bfe",
      100: "#e8a6ff",
      50: "#ffc3ff",
    },

    red: {
      900: "#620000",
      800: "#800001",
      700: "#9f050a",
      600: "#b91e1d",
      500: "#d0362d",
      400: "#e64c3e",
      300: "#f96552",
      200: "#ff856f",
      100: "#ffa58c",
      50: "#ffc2a7",
    },

    orange: {
      900: "#590000",
      800: "#770000",
      700: "#940600",
      600: "#af1b00",
      500: "#c53400",
      400: "#dd4900",
      300: "#f35e11",
      200: "#ff7b32",
      100: "#ff9c52",
      50: "#ffba6e",
    },

    green: {
      900: "#002000",
      800: "#003602",
      700: "#064c0f",
      600: "#206324",
      500: "#397a3a",
      400: "#51924f",
      300: "#69aa66",
      200: "#82c37d",
      100: "#9add95",
      50: "#b4f7ad",
    },
  },
};
