const baseStyles = {
  apply: ["textStyles.body"],
  fontWeight: 400,
  border: "2px solid",
  borderRadius: "200px",
  transition: "transform 0.15s ease-out, background 0.15s ease-out",
};

const interactiveStyles = {
  _disabled: {
    apply: "textStyles.body",
    bg: "inactive",
    borderColor: "transparent",
  },
  _hover: {
    _disabled: {
      bg: "inactive",
      transform: "none",
    },
    transform: "scale(1.02, 1.02)",
  },
  _active: {
    transform: "scale(1, 1)",
    color: "blue",
    bg: "inset",
  },
};

const variants = {
  primary: {
    ...baseStyles,
    bg: "blue",
    color: "white",
    ...interactiveStyles,
  },
  secondary: {
    ...baseStyles,
    color: "blue",
    bg: "inset",
    borderColor: "transparent",
    ...interactiveStyles,
  },
  outlined: {
    ...baseStyles,
    color: "blue",
    bg: "transparent",
    ...interactiveStyles,
  },
  ghost: {
    ...baseStyles,
    color: "blue",
    bg: "transparent",
    borderColor: "transparent",
    ...interactiveStyles,
    _disabled: {
      bg: "transparent",
    },
  },
  danger: {
    ...baseStyles,
    apply: "layerStyles.danger",
    ...interactiveStyles,
  },
};

const sizes = {
  sm: {
    field: {
      apply: "textStyles.body",
    },
  },
  md: {
    field: {
      apply: "textStyles.body",
    },
    h: "40px",
  },
  lg: {
    field: {
      apply: "textStyles.body",
      h: "60px",
    },
  },
};

export default {
  variants,
  sizes,
  defaultProps: {
    variant: "primary",
    size: "md",
  },
};
