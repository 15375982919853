const baseStyle = {
  color: "blue",
  transition: "all 0.3s",
  textDecoration: "none",
  _hover: {
    color: "blue",
    textDecoration: "underline",
  },
  _focus: {
    textDecoration: "underline",
  },
};

export default {
  baseStyle,
};
