export default {
  baseStyle: {
    content: {
      borderRadius: "8px",
      border: "none",
      boxShadow: "dark-lg",
    },
    header: {
      apply: "textStyles.label",
      border: "none",
      fontWeight: "normal",
      bg: "lightBlue",
      color: "white",
      borderRadius: "8px 8px 0 0",
    },
    body: {
      py: 4,
    },
    footer: {
      border: "none",
      display: "flex",
      justifyContent: "flex-end",
    },
  },
};
