export default {
  baseStyle: {
    container: {
      _hover: {
        boxShadow: "0 0 0 2px var(--chakra-colors-blue)",
      },
      _active: {
        boxShadow: "0 0 0 2px var(--chakra-colors-lightBlue)",
      },
    },
  },
};
