export const FONT_WEIGHT = {
  NORMAL: "normal",
  MEDIUM: "500",
};

const textStyles = {
  heading: {
    fontSize: "32px",
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: "42px",
    color: "black",
  },
  title: {
    fontSize: "22px",
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: "32px",
    color: "black",
  },
  label: {
    fontSize: "16px",
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: "24px",
    color: "gray",
  },
  body: {
    fontSize: "16px",
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: "24px",
    color: "gray",
  },
  small: {
    fontSize: "12px",
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: "32px",
    color: "gray",
  },
  caption: {
    fontSize: "12px",
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: "32px",
    textTransform: "uppercase",
    color: "blue",
  },
  navigation: {
    fontSize: "14px",
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: "32px",
    color: "gray",
  },
};

export type TextStyles = typeof textStyles;

export default textStyles;
