export default {
  baseStyle: {
    container: {
      transition: "transform 0.15s ease-out, background 0.15s ease-out",
      _hover: {
        "span[class*='radio__control']:not([data-disabled])": {
          borderColor: "blue",
          transform: "scale(1.25, 1.25)",
        },
      },
      _focus: {
        "span[class*='checkbox__control']:not([data-disabled])": {
          borderColor: "blue",
          transform: "scale(1.05, 1.05)",
        },
      },
      _invalid: {
        "span[class*='checkbox__control']:not([data-disabled])": {
          borderColor: "red",
        },
      },
    },
    control: {
      bg: "inset",
      rounded: "8px",
      w: "40px",
      h: "40px",
      border: "2px solid",
      borderColor: "blue",
      _checked: {
        bg: "blue",
        borderColor: "transparent",
        _hover: {
          bg: "blue",
        },
      },
      _focus: {
        borderColor: "blue",
        transform: "scale(1.05, 1.05)",
      },
      _invalid: {
        borderColor: "red",
        bg: "red",
      },
    },
    label: {
      apply: ["textStyles.label"],
      _invalid: {
        color: "red",
      },
    },
  },
};
