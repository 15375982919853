import { extendTheme } from "@chakra-ui/react";
import * as components from "./components";
import * as foundations from "./foundations";
import { CalendarDefaultTheme } from "datepicker";
// import datepicker from "./components/datepicker";

const baseTheme: Record<string, any> = extendTheme({
  ...foundations,
  colors: {
    ...foundations.colors.normal,
    brand: foundations.colors.normal.blue,
  },
  fonts: foundations.fonts,
  textStyles: foundations.textStyles,
  components: {
    ...components,
  },
});

export const theme: Record<string, string> = extendTheme(
  baseTheme,
  CalendarDefaultTheme,
);
