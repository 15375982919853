const layerStyles = {
  muted: {
    color: "muted !important",
    borderColor: "muted !important",
  },
  danger: {
    color: "red !important",
    borderColor: "red !important",
  },
  success: {
    color: "green !important",
    borderColor: "green !important",
  },
  info: {
    color: "blue !important",
    borderColor: "blue !important",
  },
  warn: {
    color: "orange.500 !important",
    borderColor: "orange.500 !important",
  },
};

export type LayerStyles = typeof layerStyles;
export default layerStyles;
