import type { StyleFunctionProps } from "@chakra-ui/theme-tools";

const variants = {
  default: (props: StyleFunctionProps) => ({
    field: {
      border: "2px solid",
      borderRadius: "8px",
      bg: "inset",
      borderColor: "transparent",
      _hover: { borderColor: "blue" },
      _focus: {
        borderColor: "blue",
      },
      _disabled: {
        borderColor: "transparent",
        bg: "inactive",
        color: "gray",
      },
      _invalid: {
        borderColor: "red",
        bg: "white",
        color: "red",
      },
    },
  }),
};

const sizes = {
  sm: {
    field: {
      apply: "textStyles.body",
    },
  },
  md: {
    field: {
      apply: "textStyles.body",
    },
  },
  lg: {
    field: {
      apply: "textStyles.body",
      h: "60px",
    },
  },
};

export default {
  variants,
  sizes,
  defaultProps: {
    variant: "default",
    size: "lg",
  },
};
